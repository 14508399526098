import React from "react";
import Services from "./Services";
import ServicesImages from "./ServicesImages"



const ServicesFull = () => {
  return (
    <>
        <Services />
        <ServicesImages />
    </>
  );
};

export default ServicesFull;
